import { EnvironmentSecrets } from './types/secrets';

export const stagingSecrets: EnvironmentSecrets = {
  testFirebaseUserUserName: '',
  testFirebaseUserPassword: '',
  domain: 'staging-serviceai.us.auth0.com',
  clientId: 'ryPYc78N9uYrQrUjsysh2cXAHgAuhdlR',
  loginUrl: 'https://serviceai.staging.impel.io/login',
  cloudFunctionUrl: 'us-central1-brain-ui-v1-staging.cloudfunctions.net',
};
